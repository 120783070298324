import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

// 最終ログイン日時
export const lastLoginState = atom<{
    time?: string // YYYYMMDD
}>({
    key: 'lastLoginState',
    default: {},
    effects_UNSTABLE: [persistAtom],
})

// JWTトークン情報
export const jwtTokenState = atom<{
    username: string
    user_organization_id: string
    token: string
    defaultRole: string
    allowedRoles: string[]
    organizationMaterializedPath: string
}>({
    key: 'jwtTokenState',
    default: undefined,
})

// ユーザ情報
export const userState = atom<{
    email: string
    first_name: string
    is_active: boolean
    is_staff: boolean
    is_superuser: boolean
    last_name: string
    username: string
    user_organization_id: string
    organization_id: string
}>({
    key: 'userState',
    default: undefined,
})
