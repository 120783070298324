// APIサーバのパス
export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL ?? 'http://localhost:8000'
export const LOGIN_URL =
    typeof window !== 'undefined'
        ? `${API_BASE_URL}/api/a/login/?next=${window.location.href}`
        : `${API_BASE_URL}/api/a/login/`
export const LOGOUT_URL = `${API_BASE_URL}/api/a/logout/?next=/accounts/logout_complete/`
export const JWT_TOKEN_URL = `${API_BASE_URL}/api/v3/accounts/generate_jwt`

// GraphQLサーバのパス
export const HASURA_BASE_URL = process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL ?? 'http://localhost:8080'
export const GRAPHQL_URI = `${HASURA_BASE_URL}/v1/graphql`

// 機材管理システムへのパス
export const MM_SERVER_URL = process.env.NEXT_PUBLIC_MM_SERVER_URL ?? 'http://localhost:8080'
