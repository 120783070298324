import { gql, PureQueryOptions, RefetchQueriesFunction } from '@apollo/client'
import dayjs from 'dayjs'
import { SelectedSchedule } from '../../store/ScheduleState'
import { isStaff } from '../util/UserUtil'

// ユーザ情報取得
export const GET_USER_INFO = gql`
    query GetUserInfo($username: String) {
        auth_user(where: { username: { _eq: $username } }) {
            username
            last_name
            first_name
            email
            is_active
            is_staff
            is_superuser
            last_login
            nile_farm_userorganizations {
                id
                nile_farm_organization {
                    id
                    name
                }
            }
        }
    }
`

// 農場リスト取得
export const GET_FARM_LIST = gql`
    query GetFarmList {
        nile_farm_organization(order_by: { materialized_path: asc }) {
            id
            materialized_path
            name
            nile_farm_farms(where: { valid_end: { _gt: "now()" } }) {
                id
            }
        }
    }
`

// 圃場情報を取得
export const GET_FIELDS = gql`
    query GetFields($farmIds: [uuid!] = []) {
        nile_farm_farm(where: { id: { _in: $farmIds }, valid_end: { _gt: "now()" } }) {
            id
            area_unit
            nile_farm_fields(where: { valid_end: { _gt: "now()" } }) {
                id
                name
                memo
                area_center_point
                address
                version
                nile_farm_fieldsurveys(where: { valid_end: { _gt: "now()" } }) {
                    id
                    content_polygon
                    area_size
                    drone_home_point
                    content
                    nile_farm_missions(
                        limit: 1
                        order_by: {
                            nile_operation_flights_aggregate: {
                                max: { flight_started: desc_nulls_last }
                            }
                        }
                    ) {
                        id
                        name
                        nile_operation_flights(limit: 1, order_by: { flight_started: desc }) {
                            flight_username
                            flight_user_id
                            flight_started
                            auth_user {
                                first_name
                                last_name
                                username
                            }
                        }
                    }
                    nile_farm_missiongenerated {
                        result_code
                        memo
                    }
                }
            }
            nile_farm_organization {
                name
            }
            nile_farm_obstaclesurveys(where: { valid_end: { _gt: "now()" } }) {
                id
                content_polygon
                nile_farm_obstaclekind {
                    name
                    code
                }
            }
        }
    }
`

// 圃場のミッション、飛行履歴を取得
export const GET_OPERATION_FLIGHTS = gql`
    query GetOperationFlights($id: uuid = "00000000-0000-0000-0000-000000000000") {
        nile_farm_mission(
            where: {
                nile_farm_fieldsurvey: { nile_farm_field: { id: { _eq: $id } } }
                valid_end: { _gt: "now()" }
            }
        ) {
            id
            name
            valid_end
        }
        nile_operation_flight(
            where: {
                nile_farm_mission: {
                    nile_farm_fieldsurvey: {
                        nile_farm_field: { id: { _eq: $id }, valid_end: { _gt: "now()" } }
                        valid_end: { _gt: "now()" }
                    }
                }
            }
            limit: 20
            order_by: { flight_started: desc }
        ) {
            id
            flight_started
            drone_ac_serial_number
            nile_farm_mission {
                name
            }
            auth_user {
                first_name
                last_name
                username
            }
        }
    }
`

// チームの習得（自分が所属する組織のメンバが作成したチームをすべて習得）
export const GET_TEAMS = gql`
    query GetTeams($username: String = "") {
        nile_farm_team(
            order_by: { created: desc }
            where: {
                valid_end: { _gt: "now()" }
                nile_farm_userorganization: {
                    nile_farm_organization: {
                        nile_farm_userorganizations: { auth_user: { username: { _eq: $username } } }
                    }
                }
            }
        ) {
            id
            name
            created
            memo
            nile_farm_userorganization {
                auth_user {
                    first_name
                    last_name
                    username
                }
            }
            nile_farm_teamfields {
                id
                nile_farm_field {
                    id
                    farm_id
                }
            }
            nile_farm_teamusers {
                id
            }
            total_area_size
            version
        }
    }
`

// チーム情報を無効化
export const INVALIDATE_TEAM = gql`
    mutation InvalidateTeam(
        $id: uuid = "00000000-0000-0000-0000-000000000000"
        $version: bigint = ""
        $data: nile_farm_team_set_input = {}
    ) {
        update_nile_farm_team(
            where: { id: { _eq: $id }, version: { _eq: $version } }
            _set: $data
        ) {
            returning {
                id
            }
        }
    }
`

// Staff用月表示スケジュール
export const GET_MONTH_SCHEDULE_STAFF = gql`
    query GetMonthScheduleStaff($search_start: timestamptz = "", $search_end: timestamptz = "") {
        nile_farm_schedule(
            where: {
                valid_end: { _gt: "now()" }
                start: { _gte: $search_start, _lte: $search_end }
            }
        ) {
            id
            name
            memo
            start
            end
            accept_start
            accept_end
            nile_farm_schedulereservations(where: { valid_end: { _gt: "now()" } }) {
                id
                memo
                adopted
                created
                version
                nile_farm_userorganization {
                    id
                    auth_user {
                        id
                        first_name
                        last_name
                        username
                    }
                }
            }
            nile_farm_userorganization {
                auth_user {
                    email
                }
            }
        }
    }
`

// User用月表示スケジュール・自分に割り当てられたスケジュールのみ表示
export const GET_MONTH_SCHEDULE = gql`
    query GetMonthSchedule(
        $search_start: timestamptz = ""
        $search_end: timestamptz = ""
        $username: String = ""
    ) {
        nile_farm_schedule(
            where: {
                valid_end: { _gt: "now()" }
                start: { _gte: $search_start, _lte: $search_end }
                nile_farm_scheduletargetusers: {
                    nile_farm_userorganization: { auth_user: { username: { _eq: $username } } }
                }
            }
        ) {
            id
            name
            memo
            start
            end
            accept_start
            accept_end
            nile_farm_schedulereservations(where: { valid_end: { _gt: "now()" } }) {
                id
                memo
                adopted
                created
                version
                nile_farm_userorganization {
                    id
                    auth_user {
                        id
                        first_name
                        last_name
                        username
                    }
                }
            }
            nile_farm_userorganization {
                auth_user {
                    email
                }
            }
        }
    }
`

// User用リスト表示スケジュール・自分に割り当てられたスケジュールのみ表示
export const GET_MY_SCHEDULE_LIST = gql`
    query GetMyScheduleList($username: String = "") {
        nile_farm_schedule(
            where: {
                valid_end: { _gt: "now()" }
                nile_farm_scheduletargetusers: {
                    nile_farm_userorganization: { auth_user: { username: { _eq: $username } } }
                }
            }
            order_by: { start: asc }
        ) {
            id
            name
            memo
            start
            end
            accept_start
            accept_end
            nile_farm_schedulereservations(where: { valid_end: { _gt: "now()" } }) {
                id
                memo
                adopted
                created
                version
                nile_farm_userorganization {
                    id
                    auth_user {
                        id
                        first_name
                        last_name
                        username
                    }
                }
            }
            nile_farm_userorganization {
                id
                auth_user {
                    id
                    first_name
                    last_name
                    username
                    email
                }
            }
        }
    }
`

// スケジュール管理リスト用（自分が所属する組織以下のスケジュールを取得）
export const GET_SCHEDULE_LIST = gql`
    query GetScheduleList($organizationPath: String = "") {
        nile_farm_schedule(
            where: {
                valid_end: { _gt: "now()" }
                nile_farm_userorganization: {
                    nile_farm_organization: { materialized_path: { _like: $organizationPath } }
                }
            }
            order_by: { start: asc }
        ) {
            id
            name
            memo
            start
            end
            accept_start
            accept_end
            nile_farm_userorganization {
                id
                auth_user {
                    id
                    first_name
                    last_name
                    username
                    email
                }
            }
            version
            nile_farm_scheduletargetusers {
                id
                nile_farm_userorganization {
                    id
                    auth_user {
                        id
                        email
                        first_name
                        last_name
                        username
                    }
                }
            }
            nile_farm_schedulereservations(where: { valid_end: { _gt: "now()" } }) {
                id
                nile_farm_userorganization {
                    id
                    auth_user {
                        id
                        username
                    }
                }
            }
        }
    }
`

// スケジュール日サマリ用
export const GET_SCHEDULE_SUMMARY = gql`
    query GetScheduleSummary($id: [uuid!] = []) {
        nile_farm_schedule(where: { id: { _in: $id }, valid_end: { _gt: "now()" } }) {
            id
            series_id
            name
            memo
            start
            end
            accept_start
            accept_end
            version
            nile_farm_userorganization {
                id
                auth_user {
                    id
                    first_name
                    last_name
                    username
                }
                nile_farm_organization {
                    id
                    name
                    materialized_path
                    id
                }
            }
            nile_farm_schedulereservations(where: { valid_end: { _gt: "now()" } }) {
                id
                memo
                adopted
                created
                version
                nile_farm_userorganization {
                    id
                    auth_user {
                        id
                        first_name
                        last_name
                        username
                        email
                    }
                }
                nile_farm_schedulereservationfields {
                    id
                    version
                    nile_farm_field {
                        id
                        nile_farm_fieldsurveys {
                            area_size
                        }
                        nile_farm_farm {
                            id
                        }
                    }
                }
            }
            nile_farm_scheduletargetusers {
                id
                nile_farm_userorganization {
                    id
                    auth_user {
                        id
                        email
                        first_name
                        last_name
                        username
                        nile_farm_userorganizations {
                            id
                        }
                    }
                }
            }
        }
    }
`

// スケジュールの無効化
export const INVALIDATE_SCHEDULE = gql`
    mutation InvalidateSchedule($id: [uuid!] = []) {
        update_nile_farm_schedule(
            where: { id: { _in: $id } }
            _set: { valid_end: "now()" }
            _inc: { version: 1 }
        ) {
            returning {
                id
            }
        }
    }
`

// 複数選択している圃場の詳細情報を取得
export const GET_SELECTED_FIELDS_DETAIL = gql`
    query GetSelectedFieldsDetail($ids: [uuid!] = []) {
        nile_farm_field(where: { id: { _in: $ids } }) {
            address
            memo
            name
            nile_farm_fieldsurveys(where: { valid_end: { _gt: "now()" } }) {
                area_size
                drone_home_point
                content_polygon
                nile_farm_missions(where: { valid_end: { _gt: "now()" } }) {
                    name
                    id
                    content
                    nile_operation_flights {
                        flight_started
                        auth_user {
                            last_name
                            first_name
                        }
                    }
                }
            }
        }
    }
`

// スケジュール情報更新時のリフェッチ対象
export const scheduleRefetchQueries = (
    refetchMonths: dayjs.Dayjs[],
    username: string,
    allowedRoles: string[],
    organizationMaterializedPath: string,
    selectedSchedules?: Array<SelectedSchedule>
): Array<string | PureQueryOptions> | RefetchQueriesFunction => {
    const refetchQueries: Array<string | PureQueryOptions> | RefetchQueriesFunction = []

    const alreadyAddMonth: dayjs.Dayjs[] = []

    refetchQueries.push({ query: GET_MY_SCHEDULE_LIST, variables: { username } })

    refetchMonths.forEach((refetchMonth) => {
        const search_start = refetchMonth.startOf('month')
        const search_end = search_start.add(1, 'month')
        if (alreadyAddMonth.indexOf(search_start) > -1) return
        alreadyAddMonth.push(search_start)

        if (isStaff(allowedRoles)) {
            refetchQueries.push({
                query: GET_SCHEDULE_LIST,
                variables: { organizationMaterializedPath },
            })
            refetchQueries.push({
                query: GET_MONTH_SCHEDULE_STAFF,
                variables: { search_start, search_end },
            })
        } else {
            refetchQueries.push({
                query: GET_MONTH_SCHEDULE,
                variables: { search_start, search_end, username },
            })
        }
    })

    selectedSchedules?.forEach((s) => {
        refetchQueries.push({ query: GET_SCHEDULE_SUMMARY, variables: { id: s.id } })
    })

    return refetchQueries
}

export const SEND_EMAIL = gql`
    mutation SendEmail($body: String = "", $subject: String = "", $to: String = "") {
        rs1SendEmail(sendEmailInput: { body: $body, subject: $subject, to: $to })
    }
`

// 飛行履歴情報を取得
export const GET_FLIGHT_HISTORY = gql`
    query GetFlightHistory($term_start: timestamptz = "", $term_end: timestamptz = "") {
        nile_operation_flight(
            where: { created: { _gt: $term_start, _lt: $term_end } }
            order_by: { created: asc }
        ) {
            id
            created
            flight_started
            flight_ended
            drone_ac_serial_number
            nile_farm_mission {
                id
                name
                nile_farm_fieldsurvey {
                    id
                    area_size
                    nile_farm_field {
                        id
                        name
                        nile_farm_farm {
                            id
                            nile_farm_organization {
                                id
                                name
                            }
                        }
                    }
                }
            }
            auth_user {
                first_name
                last_name
                username
            }
        }
    }
`

export const GET_ORGANIZATION_TREE = gql`
    query GetOrganizationTree($user_org_id: uuid = "00000000-0000-0000-0000-000000000000") {
        nile_farm_organization(
            where: { nile_farm_userorganizations: { id: { _eq: $user_org_id } } }
        ) {
            id
            name
            nile_farm_organizations {
                id
                name
                nile_farm_organizations {
                    id
                    name
                    nile_farm_organizations {
                        id
                        name
                        nile_farm_organizations {
                            id
                            name
                            nile_farm_organizations {
                                id
                                name
                                nile_farm_organizations {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export const GET_USER_FLIGHT_RESULTS = gql`
    query GetUserFlightResults($term_start: timestamptz = "", $term_end: timestamptz = "") {
        nile_farm_userorganization(order_by: { nile_farm_organization: { name: asc } }) {
            id
            auth_user {
                id
                username
                last_name
                first_name
                nile_operation_flights(
                    order_by: { flight_started: desc }
                    where: { created: { _gt: $term_start, _lt: $term_end } }
                ) {
                    id
                    flight_started
                    flight_ended
                }
            }
            nile_farm_organization {
                id
                name
            }
        }
    }
`

export const GET_FARM_BY_FIELD = gql`
    query GetFarmByField($fieldId: uuid!) {
        nile_farm_farm(where: { nile_farm_fields: { id: { _eq: $fieldId } } }) {
            id
        }
    }
`

export const GET_FARM_AND_FIELD_BY_MISSION = gql`
    query GetFarmAndFieldByMission($missionId: uuid!) {
        nile_farm_mission(where: { id: { _eq: $missionId } }) {
            nile_farm_fieldsurvey {
                nile_farm_field {
                    id
                    nile_farm_farm {
                        id
                    }
                }
            }
        }
    }
`

export const GET_FARM_BY_FARMID = gql`
    query GetFarmByFarmId($farmIds: [uuid!]!) {
        nile_farm_farm(where: { id: { _in: $farmIds } }) {
            id
        }
    }
`

export const JZ_CHECK_SKIP_AREA_FRAGMENT = gql`
    fragment JZCheckSkipArea on JZCheckSkipAreaType {
        id
        name
        contentPolygon
        memo
    }
`

export const GET_JZ_CHECK_SKIP_AREA = gql`
    query GetJzCheckSkipArea {
        rs1JzCheckSkipArea {
            ...JZCheckSkipArea
        }
    }
`

export const INSERT_JZ_CHECK_SKIP_AREA = gql`
    mutation InsertJzCheckSkipArea($contentPolygon: JSON!, $name: String!, $memo: String = "") {
        rs1InsertJzCheckSkipArea(
            jzCheckSkipAreaInsertInput: {
                contentPolygon: $contentPolygon
                name: $name
                memo: $memo
            }
        ) {
            ...JZCheckSkipArea
        }
    }
`

export const UPDATE_JZ_CHECK_SKIP_AREA = gql`
    mutation UpdateJzCheckSkipArea(
        $contentPolygon: JSON!
        $id: UUID!
        $memo: String = ""
        $name: String!
    ) {
        rs1UpdateJzCheckSkipArea(
            jzCheckSkipAreaUpdateInput: {
                contentPolygon: $contentPolygon
                id: $id
                memo: $memo
                name: $name
            }
        ) {
            ...JZCheckSkipArea
        }
    }
`

export const DELETE_JZ_CHECK_SKIP_AREA = gql`
    mutation DeleteJzCheckSkipArea($id: UUID!) {
        rs1DeleteJzCheckSkipArea(id: $id)
    }
`
