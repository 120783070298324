import {
    ApolloClient,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    split,
    useQuery,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import blue from '@mui/material/colors/blue'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { jaJP } from '@mui/x-data-grid'
import { jaJP as pickersJaJP } from '@mui/x-date-pickers'
import { jaJP as coreJaJP } from '@mui/material/locale'
import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import dayjs from 'dayjs'
import jwtDecode from 'jwt-decode'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useState } from 'react'
import { RecoilRoot, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { createGlobalStyle } from 'styled-components'
import { GET_USER_INFO } from '../common/const/Query'
import { GRAPHQL_URI, JWT_TOKEN_URL, LOGIN_URL } from '../common/const/UrlConst'
import { DecodedJwt } from '../common/entity/UserToken'
import { initAxiosConfig } from '../common/util/NsAxiosHelper'
import { canLogin } from '../common/util/UserUtil'
import { GetUserInfoQuery } from '../generated/graphql'
import { jwtTokenState, userState } from '../store/UserState'

// Axiosの初期化
initAxiosConfig()

/**
 * ユーザ情報を取得してからコンポーネントを表示
 */
const ComponentWithUserInfo: React.FC<{ props: AppProps }> = (param) => {
    const token = useRecoilValue(jwtTokenState)
    const setUserState = useSetRecoilState(userState)

    // ユーザ情報取得処理
    const query = useQuery<GetUserInfoQuery>(GET_USER_INFO, {
        variables: { username: token?.username },
    })

    useEffect(() => {
        const data = query.data
        if (!data) return
        if (data.auth_user && data.auth_user.length > 0) {
            const user = data.auth_user[0]
            const org = user.nile_farm_userorganizations?.[0]
            const user_organization_id = org?.id
            const organization_id = org?.nile_farm_organization?.id
            const userInfo = { ...user, user_organization_id, organization_id }
            setUserState(userInfo)
            Sentry.setContext('userInfo', userInfo)
        }
    }, [query.data, setUserState])

    if (!query.data) {
        return <>Loading User Info...</>
    }

    return (
        <SnackbarProvider maxSnack={3}>
            <param.props.Component {...param.props.pageProps} />
        </SnackbarProvider>
    )
}

/**
 * ログインしている場合のみコンポーネントを表示
 */
const ComponentWithLogin: React.FC<{ props: AppProps }> = (param) => {
    const [token, setToken] = useRecoilState(jwtTokenState)
    const [error, setError] = useState('')

    useEffect(() => {
        // JWTトークンをAPIサーバから取得する
        axios
            .get(JWT_TOKEN_URL)
            .then((value) => {
                const decodedJwt = jwtDecode<DecodedJwt>(value.data.token)
                setToken({
                    ...value.data,
                    user_organization_id: decodedJwt.user_organization_id,
                    organizationMaterializedPath:
                        decodedJwt['https://hasura.io/jwt/claims'][
                            'x-hasura-organization-materialized-path'
                        ],
                    loginTime: dayjs(),
                })
            })
            .catch((ex) => {
                // eslint-disable-next-line no-console
                console.error(ex)
                setError('Login Error: ' + ex.message)
            })
    }, [setToken])

    if (!token) {
        return (
            <div>
                <button
                    onClick={() => {
                        location.reload()
                    }}
                >
                    Reload
                </button>
                <br />
                {error}
            </div>
        )
    }

    const getWebSocketSplitLink = (uri: string) => {
        const httpLinkApi = new HttpLink({
            uri: uri,
            headers: { authorization: `Bearer ${token.token}` },
        })
        const wsLinkApi = new WebSocketLink({
            uri: uri.replace(/http:\/\//, 'ws://').replace(/https:\/\//, 'wss://'),
            options: {
                reconnect: true,
                connectionParams: {
                    headers: { authorization: `Bearer ${token.token}` },
                },
            },
        })
        return split(
            ({ query }) => {
                const definition = getMainDefinition(query)
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                )
            },
            wsLinkApi,
            httpLinkApi
        )
    }

    const linkApi = getWebSocketSplitLink(GRAPHQL_URI)

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: linkApi,
    })

    if (!canLogin(token.allowedRoles))
        return (
            <div>
                お使いのアカウントでは本サービスを利用できません。
                <a href={LOGIN_URL}>別のアカウントでログイン</a>
            </div>
        )

    const theme = createTheme(
        {
            palette: {
                primary: {
                    main: blue[800],
                },
                // type: 'dark',
            },
        },
        jaJP, // x-data-grid translations
        pickersJaJP, // x-date-pickers translations
        coreJaJP // mui translations
    )
    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <ComponentWithUserInfo props={param.props} />
            </ThemeProvider>
        </ApolloProvider>
    )
}

/**
 * Appルート
 */
const App = (props: AppProps): JSX.Element => {
    return (
        <RecoilRoot>
            <Head>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no, viewport-fit=cover"
                />
                <title>Field Viewer</title>
                <meta name="theme-color" content="#317EFB" />
            </Head>
            <ComponentWithLogin props={props} />
        </RecoilRoot>
    )
}

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: Roboto;
    -webkit-app-region: drag;
    height: 100%;
    overflow: hidden;
    padding: env(safe-area-inset);
  }
  body {
    background-color: rgb(40, 44, 52);
    color: rgb(0, 0, 0);
    height: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0
  }
  #root {
    height: 100%;
    width: 100%;
  }
`

export default App
