export const canLogin = (allowedRoles?: string[]): boolean => {
    if (!allowedRoles) return false
    return allowedRoles.some(
        (role) => role === 'nileworks_staff' || role === 'sharing_staff' || role === 'sharing_user'
    )
}

export const canUseAnalytics = (allowedRoles?: string[]): boolean => {
    if (!allowedRoles) return false
    return allowedRoles.some((role) => role === 'nileworks_staff')
}

export const isNileStaff = (allowedRoles?: string[]): boolean => {
    if (!allowedRoles) return false
    return allowedRoles.some((role) => role === 'nileworks_staff')
}

export const isStaff = (allowedRoles?: string[]): boolean => {
    if (!allowedRoles) return false
    return allowedRoles.some((role) => role === 'nileworks_staff' || role === 'sharing_staff')
}

// 対象の組織が自分の組織以下かどうかを返す
export const isUnderOrganization = (
    organizationMaterializedPath?: string,
    targetOrgPath?: string
): boolean => {
    if (!organizationMaterializedPath) return false
    if (!targetOrgPath) return false
    return targetOrgPath.search(organizationMaterializedPath.replaceAll('%', '.*')) >= 0
}
