import axios from 'axios'
import { LOGIN_URL } from '../const/UrlConst'

export const goLoginPage = (): void => {
    if (typeof window !== 'undefined') {
        window.location.href = LOGIN_URL
    }
}

// Axiosの設定を初期化
export const initAxiosConfig = (): void => {
    // Appサーバにアクセスするために必要なヘッダーを設定
    axios.defaults.xsrfHeaderName = 'X-CSRFToken'
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.withCredentials = true

    // 認証エラーの場合はログインページに飛ばす
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            const status = error.response ? error.response.status : null
            // if (status !== 401 && status !== 403) {
            if (status !== 401) {
                return Promise.reject(error)
            }
            goLoginPage()
            return Promise.reject(error)
        }
    )
}
